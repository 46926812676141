export const patientIRD: any = {
  mainMenu: "Main Navigational Menu Consumer GCSO - Global",
  footerMenu: "Footer Navigation Menu Consumer GCSO - Global",
  banner: "Banner - HTML - IRD - DTC - US Global",
  carousel: "",
  footer: "Retina Global Footer Consumer - US - Global",
  exitPopup: "Retina Canada Exit Popup New - Global",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Global",
  topNav: "Top Navigational Menu Consumer US - Global",
  video1: "How do inherited retinal diseases happen -Inherited Retinal Disease - Global",
  video2: "How are retinal diseases inherited - Inherited Retinal Disease - Global",
  infographic: "Infographic Section - Inherited Retinal Disease - Global",
  footerCallout: "Navigational Callout - Inherited Retinal Disease - Global",
  siteLogo: "Site logos - Global",
  video1GTMLabel: "Genes 101",
  video2GTMLabel:  "Inheritance 101",
  ifYouDoSection: "If You Do - Inherited Retinal Disease - Global",
  hardToTell: "It's Hard Tell -  Inherited Retinal Disease - Global",
  accordionSection: "Accordion Section - Inherited Retinal Disease New - Global",
  irdDefines: "Inherited Retinal Disease Defines - Inherited Retinal Disease - Global",
  familyHistory: "Family history alone - Inherited Retinal Disease - Global",
  calloutSection: "Callout Section - Inherited Retinal Disease - Global",
  whatAreGenes: "What Are Genes - Inherited Retinal Disease - Global",
  slider: "Slider - Inherited retinal disease - Global",
  closerLook: "Let’s take a closer look - Inherited Retinal Disease - Global",
  redirectLangUrl: {
    "redirectUrl": {
      "en": "/inherited-retinal-diseases",
      "es": "/es/inherited-retinal-diseases"
    }
  },
  backToTopGTM: "Back to top - Inherited Retinal Diseases",
  video1Attrs: {
    viewTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "Hide transcript - Genes 101",
        "aria-label": "Hide transcript for Genes 101"
      }
    ],
    hideTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "Hide transcript - Genes 101",
        "aria-label": "Hide transcript for Genes 101"
      }
    ]
  },
  video2Attrs: {
    viewTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "Hide transcript - Inheritance 101",
        "className": "",
        "aria-label": "Hide transcript for Inheritance 101"
      }
    ],
    hideTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "Hide transcript - Inheritance 101",
        "className": "",
        "aria-label": "Hide transcript for Inheritance 101"
      }
    ]
  },
  sliderAttrs: {
    bulletGTMLabels: [
      'Inside the Eye graphic Step 1',
      'Inside the Eye graphic Step 2',
      'Inside the Eye graphic Step 3'
    ],
    bulletAriaLabels: [
      'Slide 1',
      'Slide 2',
      'Slide 3'
    ],
    prevBtnGTMLabel: {
      'data-gtm-event-label': 'Inside the Eye graphic - previous',
      'className': 'gtm-accordion',
      'aria-label': 'Previous slide'
    },
    nextBtnGTMLabel: {
      'data-gtm-event-label': 'Inside the Eye graphic - next',
      'className': 'gtm-accordion',
      'aria-label': 'Next slide'
    }
  },
  footerClassName: "inherited-retinal-diseases-footer"
}
