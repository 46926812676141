import styled from 'styled-components';
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device'

export const PatientInhetedRetinalUs = styled.div`
.accordion-section {
  .panel-group {
    .panel {
      .panel-heading {
        .panel-title {
          &.onExpanded {
            h3,
            h4 {
              &.o-text-title {
                button {
                  &.accordion-title-button {
                    color: #8000BE;
                  }
                }
              }
            }
          }

          .accordion-icon {
            @media ${device.ipadLandscapemin} and  ${device.laptopair2max} {
              padding: 0;
            }
            &:before {
              @media ${device.ipadLandscapemin} and  ${device.desktopmenu} {
                margin-left: 0;
              }
            }
          }

          h3,
          h4 {
            &.o-text-title {
              width: 100%;
              margin: 0;

              @media ${device.laptopMedium} {
                padding: 12px 0;
                margin-left: -6px;
                max-width: 75%;
              }

              button {
                &.accordion-title-button {
                  font-weight: 700;
                  color: #fff;
                  font-size: 2.7rem;
                  line-height: 3.4rem;
                  font-family: "Gotham";
                  background: transparent;
                  border: unset;
                  outline: unset;

                  @media ${device.ipadLandscapemin} and  ${device.laptopair2max} {
                    padding: 0;
                  }

                  @media ${device.laptopMedium} {
                    font-size: 2rem;
                    flex-wrap: unset;
                    line-height: 2.6rem;
                    padding: 0;
                    text-align: left;
                    margin-left: 0;
                  }

                  @media ${device.mobileMedium} {
                    max-width: 75%;
                  }

                  &:after {
                    position: absolute;
                    right: 15px;
                    background-size: 35px;
                    box-shadow: rgb(0 0 0 / 40%) 0px 4px 6px;
                    border-radius: 50%;
                    width: 35px;
                    height: 35px;

                    @media ${device.laptopMedium} {
                      top: 34%;
                      right: 10px;
                      width: 40px;
                      height: 40px;
                      background-size: 40px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .panel-collapse {
        .panel-body {
          .listing-main {
            ul.listing-section {
              li {
                @media ${device.ipadLandscapemin} {
                  font-size: 2.2rem !important;
                }
              }
              .level-two-first {
                padding: 12px 15px 0px 3px !important;
                @media ${device.ipadLandscapemin} {
                  padding: 24px 0 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}


.patient-inherited-retinal {
  @media ${device.ipadair} and ${device.laptopair2max} {
    max-width: 1208px;
    margin-left: auto;
    margin-right: auto;
  }

  .footer-block--row {
    @media ${device.laptopMedium} {
      margin-bottom: -200px !important;
    }
  }
  .infographic-wrapper {
    >span {
      ~.o-container {
        padding: 0;
        @media ${device.ipadLandscapemin} {
          padding: 0 2rem;
        }
      }
    }
    .o-container {
      &.youdohtml {
        @media ${device.laptopMedium} {
          padding-bottom: 30px;
        }
      }
    }
  }

  .video-section {
    &:last-child {
      .o-container {
        .video-section {
          .video-section__inner-content {
            .o-button.video-section__inner-content__video-btn {
              @media ${device.laptopMedium} {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
  .video-section__inner-content {
    .o-header {
      @media ${device.laptopMedium} {
        margin-top: 0;
      }
    }
  }

  .wrap {
    .video-section__inner-content {
      .o-container {
        padding: 0 2rem !important;
        max-width: 718px !important;

        @media ${device.ipadLandscapemin} {
          max-width: 1208px !important;
        }
      }
      .video-transcript-copy {
        p {
          @media ${device.mobileMedium} {
            width: 90%;
          }
          @media ${device.tablet} and ${device.laptopMedium} {
            width: 96%;
          }
          @media ${device.laptopMedium} {
            line-height: 22px;
          }
        }
      }
    }

    .home-video-section {
      @media ${device.tablet} and  ${device.laptopMedium} {
        padding: 0 0.5rem;
      }
      .video-section__inner-content {
        .o-button {
          @media  ${device.tablet} and ${device.laptopMedium} {
            margin-left: 30px;
          }
        }
        .home-video {
          @media  ${device.tabletMax} {
            width: 90%;
          }
        }
      }
    }

    .video-section {
      .o-container {
        .video-section {
          .video-section__inner-content {
            .o-button.video-section__inner-content__video-btn {
              @media ${device.mobileMedium} {
                margin-left: 7px;
              }
            }
          }
        }
      }
    }
  }
}
`;

export const InheritedRetinalDiseasesPatient = styled.div`
  .acsbMenu {
    .inherited-para {
      padding-top: 80px;
      padding-bottom: 88px;

      @media ${device.laptopMedium} {
        padding-top: 27px;
        padding-bottom: 44px;
      }

      .o-container {
        @media ${device.mobileMedium} {
          padding: 0 1rem;
        }
      }

      .o-inner {
        padding-left: 100px;
        padding-right: 100px;

        @media ${device.laptopMedium} {
          padding-left: 0px;
          padding-right: 0px;
        }

        .o-internal--h2 {
          font-size: 4.2rem;
          font-weight: 700;
          line-height: 5rem;
          color: #000000;
          text-align: center;

          @media ${device.laptopMedium} {
            max-width: 260px;
            margin: auto;
            font-size: 2.5rem;
            line-height: 3rem;
          }
        }
        .o-paragraph {
          font-size: 1.8rem;
          @media ${device.ipadLandscapemin} {
            font-size: 2.2rem;
          }
        }
      }
      p {
        padding-top: 48px;
        max-width: 768px;
        margin: auto;
        text-align: center;
        color: #000000;
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 3.2rem;

        @media ${device.laptopMedium} {
          line-height: 2.2rem;
          padding: 15px 0 0;
          font-size: 1.8rem !important;
          font-weight: 500;
        }
      }
    }
  }
  .o-container {
    max-width: 1208px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 2rem;
  }
  .internal-banner {
    .internal-banner__contant {
      div.o-container {
        padding: 0 1rem;
        @media ${device.desktopsignup} {
          padding: 0 2rem;
        }
        h1.o-header--h1 {
          color: #00FFD9;
          font-family: "Gotham", Arial, Helvetica, sans-serif;
          @media ${device.laptopMedium} {
            line-height: 3.8rem;
            margin-top: 20px;
          }
          @media ${device.desktopsignup} {
            padding-left: 0;
          }

          span {
            color: #fff;
            font-family: "Gotham", Arial, Helvetica, sans-serif;
            @media ${device.laptopMedium} {
              line-height: 3.8rem;
            }
          }
        }
      }
    }
  }

  .video-section__inner-content {
    .o-container {
      padding: 0;
    }
    .home-video-section {
      .video-section__inner-content__video-btn {
        span {
          font-size: 1.7rem;
        }
        .dtp-arrow-down {
          &:before {
            height: 11px;
            width: 23px;
            position: relative;
            left: 3px;
            top: 2px;
          }
        }
      }
    }
  }

  .eyeView {
    max-width: 1208px;
    margin: 0 auto;

    @media ${device.laptopMedium} {
      padding-left: 0;
      padding-right: 0;
    }

    h2 {
      max-width: 968px;
      margin: 0 auto;
      margin-bottom: 0.5rem;
      p {
        @media ${device.laptopMedium} {
          width: 80%;
        }
      }
    }
    .generic-slider-no-image {
      @media ${device.laptopMedium} {
        padding: 0 1rem;
      }
      padding: 0;

      .slider-arrow-container {
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 48px;
        @media ${device.laptopMedium} {
          margin-top: 10px;
        }
        .slider-btn {
          width: 137px;
          height: 54px;
          background-color: #7323CA;
          margin: 0px 16px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          cursor: pointer;

          @media ${device.laptopMedium} {
            width: 75px;
            height: 52px;
            margin: 0px 10px;
          }
        }

        .swiper-button-disabled {
          background-color: #7323CA;
          opacity: 0.3;
        }
      }
     .swiper-slide {
        margin-bottom: 5px;

        .wizard-paragraph {
          font-size: 1.8rem;
          padding-right: 0;
          @media ${device.ipadLandscapemin} {
            padding-right: 50px;
            font-size: 2.2rem !important;
          }
          @media ${device.desktopsignup} {
            padding-right: 40px;
          }
        }

        &.swiper-slide-active {
          @media ${device.ipadLandscapemin} {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .infographic-wrapper {
    @media ${device.ipadLandscapemin} {
      max-width: 1208px;
    }

    >div.o-container {
      @media ${device.laptopMedium} {
        padding: 0;
        width: 100%;
      }
    }

    .o-container {
      @media ${device.laptopMedium} {
        width: 87%;
      }
    }

    .discussion-guide {
      padding-bottom: 0;

      @media ${device.laptopMedium} {
        padding: 0;
      }



      .o-paragraph {
        font-size: 1.8rem;

        @media ${device.ipadLandscapemin} {
          font-size:  2.2rem;
        }
      }
    }

    .inherit-button-wrapper {
      a {
        font-size: 1.8rem;
        padding: 14px 22px;
        @media ${device.ipadLandscapemin} {
          padding: 6px 20px;
          font-size: 2.2rem;
        }
      }
    }
  }
  .disease-from-another {
    h3 {
      @media ${device.laptopMedium} {
        width: 100%;
      }
    }
    p {
      font-size: 1.8rem;
      width: 100%;
      @media ${device.ipadLandscapemin} {
        font-size: 2.2rem;
      }
    }
    .listing-section {
      li {
        font-size: 1.8rem;

        @media ${device.ipadLandscapemin} {
          font-size: 2.2rem;
        }
      }
    }
  }
  .accordion-link-text {
    a {
      font-size: 1.8rem;

      @media ${device.ipadLandscapemin} {
        font-size: 2.2rem;
      }
    }
  }

  .footer-block--row {
    .footer-inner-block {
      @media ${device.laptopMedium} {
        padding: 16px 16px 20px !important;
      }
      .o-common-title {
        font-size: 2rem;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 5px;
        @media ${device.ipadLandscapemin} {
          font-size: 2.7rem;
        }
        &:before {
          display: none !important;
        }
      }

      &:first-child {
        img {
          width: 33px;
          padding-bottom: 16px;
          padding-top: 16px;

          @media ${device.ipadLandscapemin} {
            width: 70px;
            height: 98px;
            padding-top: 8px;
            padding-bottom: 8px;
            margin-bottom: 20px;
          }
        }
      }
      &:last-child {
        img {
          width: 33px;
          padding-top: 16px;
          padding-bottom: 16px;
          @media ${device.ipadLandscapemin} {
            width: 85px;
            height: 85px;
            padding-top: 8px;
            padding-bottom: 8px;
            margin-bottom: 23px;
            margin-top: 12px;
          }
        }
      }

      a {
        font-size: 1.8rem;
        @media ${device.ipadLandscapemin} {
          font-size: 2.2rem;
        }
      }
    }
  }
`;

export const YouAreNotAlone = styled.div`
  margin-bottom : 84px;

  @media ${device.laptopMedium} {
    margin-bottom: 10px;
  }

  .o-container {
    @media ${device.laptopMedium} {
      padding: 0 2.7rem;
    }
    .info-box {
      display: flex;
      align-items: center;
      background-color: #300064;
      padding: 27px 102px;
      position: relative;
      margin: auto;

      @media ${device.laptopMedium} {
        padding: 24px 40px;
        display: block;
      }

      .media {
        img {
          position: absolute;
          left: 100px;

          @media ${device.laptopMedium} {
            position: unset;
            left: 0;
            right: 0;
            text-align: center;
            display: block;
            margin: auto;
            width: 45px;
          }
          @media ${device.ipadLandscapemin} {
            top: 47px;
          }
        }
      }

      .o-paragraph {
        text-align: center;
        margin: 10px 0;
        padding: 0;
        display: block;
        line-height: 2.4rem;
        font-size: 1.8rem;
        color: #fff;

        @media ${device.ipadLandscapemin} {
          text-align: left;
          padding: 0 0 0 101px;
          font-weight: 500;
          font-size: 2.2rem;
          line-height: 3.2rem;
          margin-bottom: 10px;
          margin-top: 0;
        }

        @media ${device.tabletMax} {
          display: inline-block;
        }

        span {
          display: inline-block;
          color: #00FFD9;
          @media ${device.ipadLandscapemin} {
            font-size: 2.2rem;
          }
        }

        span.infobox-small-paragraph {
          font-family: "Gotham-Book";
          font-size: 1.8rem;
          line-height: 2.6rem;
          color: #fff;

          @media ${device.laptopMedium} {
            font-size: 1.4rem;
            line-height: 1.8rem;
            padding-top: 13px;
          }
        }
      }
    }
  }
`;

export const GeneVariants = styled.div`
  margin-bottom: 80px;
  margin: auto;
  box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
  max-width: 1170px;
  width: 100%;
  padding: 0 2rem;
  @media ${device.laptopMedium} {
    max-width: 718px;
    width: auto;
    padding: 0 2.7rem;
    box-shadow: none;
  }
  @media ${device.desktopsignup} {
    padding: 0;
  }
  .brightcove-react-player-loader {
    @media ${device.desktopsignup} {
      margin: 0 auto;
    }
    .video-js {
      @media ${device.tabletMax} {
        margin: 0 auto;
      }
    }
  }

  .faq-container {
    position: relative;
    background-color: #F7F7F7;
    max-width: 1170px;
    box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
    padding: 0 2rem;

    @media ${device.laptopMedium} {
      max-width: 718px;
      padding: 0 2.7rem;
    }
    @media ${device.mobileMedium} {
      padding: 0 4.2rem;
    }

    &:before {
      content: "";
      background: linear-gradient(90deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
      position: absolute;
      top: 0;
      width: 100%;
      height: 16px;
      left: 0;
      right: 0;
      z-index: 1;
    }
    .bg-color-component {

      .faq-block {
        position: relative;
        margin-top: 120px;
        @media ${device.mobileMedium} {
          padding-left: 0;
          padding-right: 0;
        }

        .o-top-space {
          padding-top: 88px;

          @media ${device.laptopMedium} {
            padding-top: 48px;
          }

          h2 {
            padding-top: 16px;
            color: #000000;
            text-align: center;
            padding-bottom: 40px;
            font-size: 4.2rem;
            font-weight: 600;
            line-height: 5rem;
            margin-bottom: 5px;

            @media ${device.laptopMedium} {
              margin-top: 0;
              padding: 0;
              font-size: 2.5rem;
              line-height: 3rem;
            }
            @media ${device.ipadLandscapemin} and ${device.minideskend}{
              width: 90%;
              margin: 0 auto 5px;
            }
          }
        }
        .o-inner {
          margin: auto;
          position: relative;
        }
        .media {
          float: left;

          @media ${device.laptopMedium} {
            float: unset;
          }

          img {
            width: 68px;

            @media ${device.laptopMedium} {
              width: 33px;
              margin: 0 auto;
              display: block;
              margin-top: 22px;
              margin-bottom: 12px;
            }
          }
        }

        .faq-question {
          max-width: 868px;
          margin: auto;
          position: relative;

          .faq-answer__faq-content {
            @media ${device.tabletMax} {
              width: 90%;
              margin: 0 auto;
            }
            @media ${device.mobileMedium} {
              width: 100%;
            }
          }
          &:last-child {
            .faq-answer {
                .faq-answer__faq-content {
                  @media ${device.tablet} and ${device.laptopMedium} {
                    margin: 0 auto 44px;
                  }
                }
            }
          }
        }
      }
      .faq-answer__faq-title, .faq-answer__faq-content {
        padding-left: 100px;
      }
      .faq-answer__faq-title {
        font-weight: 700;
        color: #8000BE;
        font-size: 2.7rem;
        line-height: 3.4rem;
        font-family: "Gotham-Bold";
        margin-bottom: 10px;

        @media ${device.laptopMedium} {
          font-size: 2rem;
          line-height: 2.4rem;
          padding-left: 0;
          margin: 0 auto;
          display: table;
        }
        strong {
          font-weight: 900;
          font-family: "Gotham", Arial, Helvetica, sans-serif;
        }
      }
      .faq-answer__faq-content {
        font-weight: 500;
        font-size: 2.2rem;
        line-height: 3.2rem;
        margin-bottom: 48px;
        padding-top: 12px;

        @media ${device.laptopMedium} {
          font-size: 1.8rem;
          line-height: 2.2rem;
          padding-top: 12px;
          padding-left: 0;
          text-align: center;
          margin-bottom: 44px;
        }

        span {
          color: #8000BE;
          font-weight: 700;

          strong {
            font-size: 1.8rem;
            font-weight: 900;

            @media ${device.ipadLandscapemin} {
              font-size: 2.2rem;
             }
          }
        }
      }
    }
  }
  > div.video-section__inner-content {
    @media ${device.laptopMedium} {
      box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
      padding-bottom: 32px;
      padding-top: 0px !important;
    }
    @media ${device.tabletMax} {
      margin-top: 4px !important;
    }
    @media  ${device.tablet} and ${device.laptopair2max} {
      margin-top: -40px !important;
    }

    &:before {
      @media ${device.laptopMedium} {
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        height: 8px;
        background-color: #F7F7F7;
        z-index: 10;
        top: -5px;
      }
    }
  }
  .video-section__inner-content {
    @media ${device.laptopMedium} {
      padding-top: 30px !important;
    }

    div {
      @media ${device.laptopMedium} {
        width: 100%;
      }
    }

    p {
      @media ${device.laptopMedium} {
        padding-bottom: 28px;
        width: 72%;
      }
      @media  ${device.tablet} and ${device.laptopMedium} {
        margin-bottom: 22px !important;
      }
    }

    .video-section__inner-content__video-btn {
      @media ${device.laptopMedium} {
        width: max-content;
      }
      @media ${device.tabletMax} {
        margin-left: -8px;
        width: max-content;
      }
      span {
        font-size: 1.7rem;

        @media ${device.laptopMedium} {
          font-size: 1.8rem !important;

        }
      }
    }

    .video-section {
      border-bottom: none;
      background-color: #F7F7F7;

      @media ${device.laptopMedium} {
        padding-bottom: 30px;
      }
    }
    .o-header {
      max-width: 700px;
      padding-bottom: 48px;
      font-size: 4.2rem;
      text-align: center;
      font-weight: bold;
      margin: auto;
      padding-top: 38px;

      @media ${device.laptopMedium} {
        padding-bottom: 20px;
        font-size: 2.5rem;
        line-height: 3rem;
        width: 80%;
        margin: auto;
      }
    }

    p {
      font-size: 2.2rem;
      line-height: 3.2rem;
      text-align: center;
      max-width: 768px;
      margin: auto;

      @media ${device.laptopMedium} {
        font-size: 1.8rem;
        width: 88%;
        line-height: 1.3;
        display: block;
        margin-bottom: 20px;
      }
      @media ${device.tabletMax} {
        width: 68%;
        margin-bottom: 45px;
      }

      @media ${device.smalldeskstrt} {
        margin-bottom: 45px;
      }
    }

    .o-button {
      &.video-section__inner-content__video-btn {
        background: #8000BE ;
        color: #fff;
        border: 1px solid #fff;
        margin-left: 0;

        &:hover {
          background: #fff;
          color: #8000BE;
          border-color: #fff;
          font-weight: 700;
        }

        i {
          &:before {
            height: 19px;
          }
        }
        span {
          @media ${device.ipadLandscapemin} {
            font-size: 2.2rem;
          }
        }
      }
    }
  }
`;

export const Card = styled.div`
  padding: 120px 0;
  padding-bottom: 48px;

  @media ${device.laptopMedium} {
    padding-top: 40px;
    width: 90%;
    margin: 0 auto;
  }
  @media ${device.tabletMax} {
    padding-bottom: 0;
  }

  .o-header {
    padding-bottom: 48px;

    p {
      font-size: 4.2rem;
      font-weight: 700;
      line-height: 5rem;
      text-align: center;

      @media ${device.laptopMedium} {
        font-size: 2.5rem;
        line-height: 3rem;

        margin: auto;
      }
    }
  }
  img {
    margin: 0 auto;
    display: table;

    @media ${device.laptopMedium} {
      width: 96%;
    }
  }

  .generic-slider-no-image {
    position: relative;
    padding-top: 0;
    padding: 0 2rem;
    overflow: hidden;
    max-width: 968px;

    @media ${device.laptopMedium} {
      padding: 0 1rem;
    }

    @media ${device.extraLargeDesktop} {
      max-width: 1008px;
    }

    .swiper-pagination {
      bottom: unset;
      top: 100px;
      background-color: #00FFD9;
      height: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      text-align: center;
      transition: 0.3s opacity;
      z-index: 10;

      @media ${device.laptopMedium} {
        top: 50px;
      }

      .swiper-progress-bar-fill {
        position: absolute;
        height: 8px;
        z-index: -1;
        background-color: #7323CA;
      }

      .swipper-prev-selected {
        background-color: #7323CA !important;
      }

      .swiper-pagination-bullet-active {
        background-color: #7323CA !important;
      }

      .swiper-pagination-bullet {
        width: 68px;
        height: 68px;
        margin: 0px !important;
        opacity: 1;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 100%;
        background-color: #00FFD9;

        &:before {
          content: "";
          background-size: 57%;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          width: 100%;
          height: 100%;
        }
        &.swiper-pagination-bullet-active {
          background-color: #7323CA;
        }
      }
    }
    .swiper-pagination-bullets {
      left: 0;
      width: 100%;

      span {
        @media ${device.laptopMedium} {
          width: 45px !important;
          height: 45px !important;
        }
      }
    }

    .swiper-wrapper {
      margin-top: 150px;
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      box-sizing: content-box;

      @media ${device.laptopMedium} {
        margin-top: 75px;
        height: auto;
      }

      .swiper-slide {
        margin: 10px auto 40px;

        @media ${device.laptopMedium} {
          width: 100% !important;
          margin-top: 5px;
          margin-bottom: 5px;
        }
        > span {
          .swiper-slide-active {
            @media ${device.ipadLandscapemin} {
              margin-bottom: 20px;
            }
          }
        }
      }

      .item {
        max-width: 950px;
        margin: auto;

        @media ${device.ipadair} and ${device.laptopair2max} {
          max-width: unset;
        }

        .item__content {
          @media ${device.laptopMedium} {
            display: block;
            width: 100%;
          }

          @media ${device.ipadLandscapemin} {
            float: left;
            display: flex;
            margin-top: 10px;
            margin-left: 29px;
          }
          @media ${device.desktopsignup} {
            margin-left: 20px;
          }

          .item__icon {
            min-width: 80px;
            margin-right: 34px;

            @media ${device.laptopMedium} {
              display: block;
              margin: 16px auto;
              width: 80px;
            }

            img {
              width: 100%;
              height: auto;
              margin: auto;
              display: block;
              vertical-align: middle;
              border-style: none;

              @media ${device.laptopMedium} {
                width: 50%;
              }
            }
          }

          p {
            color: #000000;
            font-size: 2rem;
            font-weight: 500;
            line-height: 3.2rem;

            @media ${device.laptopMedium} {
              text-align: center;
              font-size: 1.8rem;
              line-height: 2.6rem;
              padding-right: 0;
            }
          }
        }
      }
    }
    .slider-arrow-container {
      @media ${device.laptopMedium} {
        margin-top: 10px;
      }

      .slider-btn {
        @media ${device.laptopMedium} {
          width: 75px;
          height: 52px;
          margin: 0px 10px;
        }
        &:hover {
          transition: ease-in 0.5s !important;
        }
      }
    }
  }
`;

export const Video2 = styled.div `
  .video-section {
    .o-container {
      .video-section {
        background-color: #FFF;
        border-bottom: none;

        @media ${device.laptopMedium} {
          padding: 44px 5px 0;
        }

        .video-section__inner-content {
          @media ${device.laptopMedium} {
            padding-bottom: 30px;
          }

          .o-header {
            color: #000000;
            padding-bottom: 40px;
            @media ${device.laptopMedium} {
              padding-bottom: 24px;
            }
          }

          p {
            color: #000000;

            @media ${device.laptopMedium} {
              font-size: 1.8rem;
              line-height: 2.2rem !important;
              width: 99% !important;

            }
          }

          .o-button {
            &.video-section__inner-content__video-btn {
              background: #8000BE ;
              color: #fff;
              border: 1px solid #fff;

              @media  ${device.ipadLandscapemin} and ${device.laptopair2max} {
                margin-top: 20px;
              }

              @media ${device.laptopMedium} {
                margin-left: 15px;
              }

              i {
                vertical-align: baseline;
                &:before {
                  height: 11px;
                  width: 23px;
                  position: relative;
                  left: 3px;
                  top: 2px;
                }
              }

              &:hover {
                background: #fff;
                color: #8000BE;
                border-color: #fff;
                font-weight: 700;
              }

              span {
                font-size: 1.8rem;
                @media ${device.ipadLandscapemin} {
                  font-size: 2.2rem;
                }
              }
            }
          }
        }
      }
    }
  }
`;
