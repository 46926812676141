import React, { useRef, useState, useEffect } from 'react'
import Layout from "@retina-packages/retina-theme-bootstrap/packages/components/layout"
import { graphql } from 'gatsby'
import { PageWrapper } from '@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle'
import BrightcoveVideo from '@retina-packages/retina-theme-bootstrap/packages/components/BrightcoveVideo'
import { SwipperSlider } from '@retina-packages/retina-theme-bootstrap';
import { carouselSlide } from '@retina-packages/retina-theme-bootstrap/packages/components/SwipperSlider';
import MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer'
import { PatientInhetedRetinalUs, InheritedRetinalDiseasesPatient, YouAreNotAlone, GeneVariants, Card, Video2 } from './styles'
import HTMLRenderer from '@retina-packages/retina-theme-bootstrap/packages/components/HTMLRenderer'
import CircularInfographics from '@retina-packages/retina-theme-bootstrap/packages/components/InfographicDonut/CircularInfographics'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes'
import { UsHeaderContainer, HomePageFooterContainer } from "../../../css/common/style"
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders';
import retinaConfig from '../../../utils/retinaConfigs'
import { deriveSiteLogo, deriveSiteMenus, fullHTMLDataBuilder, infographicDataBuilder, videoDataBuilder } from '../../../utils'
import { patientIRD } from "./constants"
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles'
import { VideoResp } from '@retina-packages/retina-theme-bootstrap/packages/types/VideoGraphResponseType'

/** Renders inherited-retinal-diseases-page template
 *
 * @param props props
 * @returns
 */
const InheritedRetinalDiseasesTemplate = (props: Props) => {
  const domRef = useRef()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isVisibleGeneticGraph, setVisibleGeneticGraph] = useState(false)
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setVisibleGeneticGraph(true)
        observer.unobserve(domRef.current)
      }
    })
    observer.observe(domRef.current)
  }, [])

  const pgPropIRD: PageQueryResult = props.data
  const blocks = pgPropIRD.nodePage.relationships.field_paragraphs_gcso
  const pgContextsIRD = props.pageContext
  const htmlStaticFilesIRD = {
    nonSVGImages: pgContextsIRD.nonSVGImages,
    svgMediaImages: pgContextsIRD.svgMediaImages,
    allMediaDocument: pgContextsIRD.documents
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp: pgPropIRD, siteMainMenu: patientIRD.mainMenu, siteFooterMenu: patientIRD.footerMenu });
  const audience = pgPropIRD.nodePage.relationships.field_general_tags_gcso[0].name

  const bannerTitle = ""
  const carouselTitle = patientIRD.carousel
  const footerTitle = patientIRD.footer
  const exitPopupTitle = patientIRD.exitPopup
  const hcpSwitcherTitle = patientIRD.hcpSwitcher
  const topNavTitle = patientIRD.topNav

  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({ blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle });

  const siteLogos: any = deriveSiteLogo({ blocks, title: patientIRD.siteLogo })

  const htmlInheritedContent = fullHTMLDataBuilder({ blocks, title: patientIRD.irdDefines });
  const htmlFamilyContent = fullHTMLDataBuilder({ blocks, title: patientIRD.familyHistory });
  const calloutBodyText = fullHTMLDataBuilder({ blocks, title: patientIRD.calloutSection });
  const htmlwhatAreGenes = fullHTMLDataBuilder({ blocks, title: patientIRD.whatAreGenes });
  const ifYouDoHTML = fullHTMLDataBuilder({ blocks, title: patientIRD.ifYouDoSection });
  const itHardTellHTML = fullHTMLDataBuilder({ blocks, title: patientIRD.hardToTell });
  const accordHTML = fullHTMLDataBuilder({ blocks, title: patientIRD.accordionSection });
  const callOutHTML = fullHTMLDataBuilder({ blocks, title: patientIRD.footerCallout });
  const bannerHTML = fullHTMLDataBuilder({ blocks, title: patientIRD.banner })

  const validIRDBlocks = blocks.filter((x: any) => 'relationships' in x)

  const { per, text1, text2 } = infographicDataBuilder({ blocks: blocks, title: patientIRD.infographic })
  const donutInfoGraData = {
    per: `${per}`,
    circular_text: text1,
    right_text: text2
  }

  let videoData = [{}];
  let carouselSlides: carouselSlide[] = [
    { field_slide_text_gcso: { value: "" } },
  ]

  let videoText = ""
  let sliderHead = ""
  let sliderImgAlt = ""
  let sliderImg = ""

  if (validIRDBlocks.length > 0) {
    validIRDBlocks.map((val: any) => {
      const blockTitle = val.relationships.field_reusable_paragraph.label
      const data = val.relationships.field_reusable_paragraph.relationships.paragraphs
      switch (blockTitle) {
        case patientIRD.video1:
          // Retina Canada Patients - "Inherited Retinal Diseases" page - Video and text component -EN
          videoText = (data.relationships.field_paragraph_items_gcso).filter((v: any) => v?.field_para_text_gcso?.value !== undefined)?.at(0)?.field_para_text_gcso?.value
          videoData = (data.relationships.field_paragraph_items_gcso).filter((v: any) => v.field_video_section_title_gcso === null)?.at(0)
          break;
        case patientIRD.closerLook:
          sliderHead = data?.field_para_text_gcso?.value
          sliderImgAlt = data.relationships.field_para_media_gcso.at(0).field_media_image.alt
          sliderImg = data.relationships.field_para_media_gcso.at(0).relationships.field_media_image.localFile.publicURL
          break;
        case patientIRD.slider:
          // Field Carousel Slide
          carouselSlides = data.relationships.field_carousal_slide_gcso
          break;
        default:
          // do nothing
          break;
      }
    })
  }

  const videoDataPart2: VideoResp = videoDataBuilder({ blocks, title: patientIRD.video2 });

  // Returns a object for slider carousel data - ready representation of the slider
  const slideData = {
    sliderHead: sliderHead,
    sliderImg: sliderImg,
    sliderImgAlt: sliderImgAlt,
    carouselSlides: carouselSlides,
    wrapClasses: "generic-slider-no-image inherited-genetic-slider-tab o-container",
    headClasses: "inherited-retinal-diseases",
    pageBullets: true,
    sliderArrow: true,
    mediadata: htmlStaticFilesIRD
  }

  return (
    <>
      <PatientInhetedRetinalUs>
        <UsHeaderContainer>
          <HomePageFooterContainer>
            <div>
              {props.pageContext !== null && props.pageContext.metaInfo !== null && (
                <MetaTagContainer metaData={props.pageContext.metaInfo} />
              )}
              <Layout
                title={"siteTitle"}
                location={props.location}
                data={mainMenu}
                mdata={footerMenu}
                footerData={footerText}
                audience={audience}
                exitPopData={exitData}
                hcplinks={hcpSwitcher}
                topNavigation={topNavs}
                siteLogos={siteLogos}
                staticMediaFiles={htmlStaticFilesIRD}
                backToTopContent={retinaConfig.backToTopContent}
                gtmBackToTopLabel={patientIRD.backToTopGTM}
                modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
                modalCloseButtonGTMLabel={retinaConfig.modalCloseButtonGTMLabel}
                toggleExpandAllUnderline={true}
                hcpValidate={retinaConfig.hcpValidate}
                preIndexUrl={retinaConfig.preIndexUrl}
                hcpHomeUrl={retinaConfig.hcpHomeUrl}
                hcpPrefix={retinaConfig.hcpPrefix}
                siteHomeUrl={retinaConfig.siteHomeUrl}
                footerClassName={patientIRD.footerClassName}
                cpraFooter={true}
                cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
                cpraOT={true}
                cpraOTEnv={process.env.OT_ENV}
                cpraOTuuid={process.env.OT_UUID}
                languageConfig={{ ...retinaConfig.langConfig, ...patientIRD.redirectLangUrl }}
                {... { ...retinaConfig.layoutAttrs.dtcAttrs, ...retinaConfig.layoutAttrs.commonAttrs }}
              >
                <PageWrapper className='pagewrapper patient-inherited-retinal '>
                  <InheritedRetinalDiseasesPatient>
                    {/* Banner section */}
                    <MobileBanner className="mobile-banner clinical-trials-banner">
                      <HTMLRenderer data={htmlStaticFilesIRD} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
                    </MobileBanner>
                    {/* Retina Canada Patients - "Inherited Retinal Diseases" page - "Inherited retinal disease." Text component -EN  */}
                    <div className='acsbMenu'>
                      <HTMLRenderer data={htmlStaticFilesIRD} html={htmlInheritedContent} />
                    </div>
                    {/* Retina Canada Patients - "Inherited Retinal Diseases" page - "If you have an IRD" Icon & Text component-EN  */}
                    <YouAreNotAlone>
                      <HTMLRenderer data={htmlStaticFilesIRD} html={calloutBodyText} />
                    </YouAreNotAlone>
                    <div className='wrap'>
                      {/* Retina Canada Patients - "Inherited Retinal Diseases" page - Video and text component -EN  */}
                      <GeneVariants>
                        <HTMLRenderer data={htmlStaticFilesIRD} html={htmlwhatAreGenes} />
                        <div className='video-section__inner-content'>
                          <HTMLRenderer data={htmlStaticFilesIRD} html={videoText} tagName='div' />
                          <BrightcoveVideo
                            accountId={retinaConfig.accountId}
                            data={videoData}
                            viewTranscriptBtnContent={retinaConfig.viewTranscriptBtnContent}
                            hideTranscriptBtnContent={retinaConfig.hideTranscriptBtnContent}
                            {...patientIRD.video1Attrs}
                          />
                        </div>
                      </GeneVariants>
                    </div>
                    {/* slider carousel component */}
                    <Card className="eyeView">
                      <SwipperSlider data={{ ...slideData, ...patientIRD.sliderAttrs }} />
                    </Card>
                    <Video2>
                      <div className='video-section retinal-video-section'>
                        <BrightcoveVideo
                          accountId={retinaConfig.accountId}
                          data={videoDataPart2}
                          viewTranscriptBtnContent={retinaConfig.viewTranscriptBtnContent}
                          hideTranscriptBtnContent={retinaConfig.hideTranscriptBtnContent}
                          {...patientIRD.video2Attrs}
                        />
                      </div>
                    </Video2>

                    <div className='infographic-bg'>
                      <div ref={domRef} className="infographic-wrapper o-container ">
                        <HTMLRenderer data={htmlStaticFilesIRD} html={htmlFamilyContent} />
                        <CircularInfographics data={donutInfoGraData} audience={audience} />
                        <div className="o-container youdohtml">
                          <HTMLRenderer data={htmlStaticFilesIRD} html={ifYouDoHTML} />
                        </div>
                      </div>
                    </div>
                    <HTMLRenderer data={htmlStaticFilesIRD} html={itHardTellHTML} />
                    <HTMLRenderer data={htmlStaticFilesIRD} html={accordHTML} />
                    {/* Retina Canada Patients - "Inherited Retinal Diseases" page - Callout component -EN  */}
                    <HTMLRenderer data={htmlStaticFilesIRD} html={callOutHTML} />
                  </InheritedRetinalDiseasesPatient>
                </PageWrapper>
              </Layout>
            </div>
          </HomePageFooterContainer>
        </UsHeaderContainer>
      </PatientInhetedRetinalUs>
    </>
  )
}

export default InheritedRetinalDiseasesTemplate

export const pageQuery = graphql`
  query($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ... PatientIRDPageQuery
      }
    }
  }
`
